import { BannerData } from '@innedit/innedit';
import { useSelector } from 'dataformjs';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../core/sysext/CMS/components/View';
import Backoffice from '../../../core/sysext/CMS/containers/Backoffice';
import Form from '../../../core/sysext/CMS/containers/Boutique/components/new/Form';
import params from '../../../core/sysext/Website/containers/Banner/params.json';

const PageBannersUpdate: FC<PageProps> = props => {
  const {
    location,
    params: { '*': star },
  } = props;

  const boutique = useSelector((state: any) =>
    state.cms.boutiques
      ? state.cms.boutiques[state.cms.indexBoutique || 0]
      : undefined,
  );

  if (!star) {
    return <div>star obligatoire</div>;
  }

  return (
    <Backoffice location={location}>
      <CMSView>
        <Form
          {...props}
          docId={star}
          itemPathnamePrefix="/website/banners/"
          model={new BannerData({ boutique, params })}
          name="banner"
          type="update"
        />
      </CMSView>
    </Backoffice>
  );
};

export default PageBannersUpdate;
